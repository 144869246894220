<template>
	<div class="contanier">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label" style='display: inline-block;width: 75px;'>关键字: </label>
				<el-input v-model="searchKey" style="width: 250px;" :placeholder="placeholderNum"></el-input>
			</div>
			<!--销售类型  -->
			<div class="filter-item">
				<label class="label">销售类型: </label>
				<el-select v-model="type" style="width: 250px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in typeOptions" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!--销售状态  -->
			<div class="filter-item">
				<label class="label">销售状态: </label>
				<el-select v-model="state" style="width: 250px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in stateOptions" :key="index" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<br>
			<!-- 销售分组 -->
			<div class="filter-item">
				<label class="label">商品分组: </label>
				<el-select v-model="group" style="width: 250px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in groupOptions" :key="index" :label="item.GroupName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<!-- 商品品牌 -->
			<div class="filter-item">
				<label class="label">商品品牌: </label>
				<el-select v-model="brand" style="width: 250px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in brandOptions" :key="index" :label="item.BrandName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-right:10px;width:90px;" @click="handleFilter">查询</el-button>
				<!-- <el-checkbox v-if="isShowCheck" v-model="checkedShopPro" @change="showOptionalPro">仅看可选商品</el-checkbox> -->
				<!-- <el-checkbox  v-model="checkedShopPro" >仅看可选商品</el-checkbox> -->
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<el-table :data="tableDataList" v-loading="loading" height="450" style="width: 100%" ref="compSelectProTable"
			 @select-all='selectAll' :row-key="getRowKeys" @select="selectSingle" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" :selectable='selectInit' :reserve-selection="true">
				</el-table-column>
				<el-table-column prop="Name" label="商品" width="300" v-if="tablecolumn.includes(1)">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img :src='imgUrl+scope.row.ImgUrl' />
							<div class="right">
								<div class="name ellipsisCommon" style="width: 130px;-webkit-line-clamp: 3;line-clamp:3">
									{{scope.row.Name}}
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="ProductTypeValue" label="销售类型" v-if="tablecolumn.includes(2)">
					<template slot-scope="scope">
						<div v-if="scope.row.ProductType===0">单品销售</div>
						<div v-if="scope.row.ProductType===1">组合销售</div>
					</template>
				</el-table-column>
				<el-table-column prop="Spec" v-if="isShowSpecValue&&tablecolumn.includes(3)" label="规格"></el-table-column>
				<el-table-column prop="ProductPrice" label="价格" v-if="tablecolumn.includes(4)">
					<template slot-scope="scope">
						<div>¥{{scope.row.ProductPrice}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="Stock" label="库存" v-if="tablecolumn.includes(5)"></el-table-column>
				<el-table-column prop="Remark" label="备注" width="200px" v-if="ishowRemark&&tablecolumn.includes(6)"></el-table-column>
			</el-table>
			<el-checkbox style="margin-top:25px;margin-left:13px;float:left;" :disabled='checkAlldis' :value="checkedAll"
			 @change="selectCurrentAll">当前页全选</el-checkbox>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10, 15, 20, 25, 30]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>
		<div slot="footer" class="dialog-footer">
			已选中
			<span style="color:#F56C6C">{{multipleSelection.length}}</span>
			件
			<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>
	import {
		productBrandList,
		productGroupList,
	} from "@/api/goods.js";

	import apiList from "@/api/popGoods.js"
	import {
		checkNogoodslist
	} from "@/api/TurnTomySelf"
	import config from '@/config/index'

	export default {
		name: 'selectProduce',
		props: {
			api: String, //弹框商品列表请求接口名称, 统一加在 api/popGoods.js里
			selectedData: { //需要选中的商品列表
				type: Array,
				default: () => {
					return []
				}
			},
			ActivityFullId: {
				type: Number,
				default: () => {
					return 0
				}
			},
			goodsMaxlength: {
				type: Number,
				default: () => {
					return 0
				}
			},
			couponData: {
				type: Object,
				default: () => {
					return {}
				}
			},
			params: { //自定义请求参数
				type: Array,
				default: () => {
					return []
				}
			},
			isShowCheck: {
				type: Boolean,
				default: () => {
					return true
				}
			},
			isShowSpecValue: {
				type: Boolean,
				default: () => {
					return false
				}
			},
			ishowRemark: {
				type: Boolean,
				default: () => {
					return true
				}
			},
			placeholderNum: {
				type: String,
				default: () => {
					return '商品名称、编码'
				}
			},
			tablecolumn:{
				type:Array,
				default:()=>{
					return [1,2,3,4,5,6]
				}
			}
		},
		data() {
			return {
				checkAlldis: false,
				baseAPI: config.BASE_URL,
				imgUrl: config.IMG_BASE,
				searchKey: '',
				type: null,
				typeOptions: [{
						value: 0,
						label: '单品销售'
					},
					{
						value: 1,
						label: '组合销售'
					}
				],
				state: null,
				stateOptions: [{
						value: 1,
						label: '已上架'
					},
					{
						value: 2,
						label: '已售罄'
					},
					{
						value: 3,
						label: '已下架'
					}
				],
				group: null,
				groupOptions: [],
				brand: null,
				brandOptions: [],
				page: {
					total: 0,
					current: 1,
					size: 10
				},
				tableDataList: [],
				selectCount: 0,
				multipleSelection: [],
				checkedShopPro: true,
				justdata: [],
				loading: false
			}
		},
		created() {
			this.getInfor();
			this.getSingleList()

		},
		watch: {},
		computed: {
			checkedAll() {
				var val = this.multipleSelection;
				let ableSelectLength = 0 //获取当前页可选的全部页数
				var disabledLen = 0;
				var ids = val.map(item => {
					return item.ProductId
				})
				this.tableDataList.forEach(item => {
					if (ids.indexOf(item.ProductId) > -1 || item.IsDisabled) {
						ableSelectLength++;
					}
					if (item.IsDisabled) {
						disabledLen++;
					}
				});
				return (ableSelectLength > 0 && this.tableDataList.length == ableSelectLength && disabledLen != ableSelectLength) ?
					true : false;
			}
		},
		mounted() {

		},
		methods: {
			//当用户手动勾选数据行的 Checkbox 时触发的事件
			selectSingle(selection, row) {
				// console.log(this.goodsMaxlength>0,selection.length > this.goodsMaxlength)
				if ((this.goodsMaxlength > 0) && (selection.length > this.goodsMaxlength)) {
					this.$message({
						message: '自定义推荐商品，最多可添加' + this.goodsMaxlength + '个',
						type: 'error'
					})
					this.$nextTick(function() {
						this.$refs['compSelectProTable'].toggleRowSelection(row, false)
					})
					return
				}
			},
			//筛选数据
			async getInfor() {
				try {
					let result = await productBrandList()
					this.brandOptions = result.Result
					let result1 = await productGroupList()
					this.groupOptions = result1.Result
				} catch (e) {

				} finally {

				}
			},


			async getSingleList() {

				this.loading = true;
				// this.selectedIsList
				try {
					var params = this.params;
					let data = {
						ProductState: this.state,
						Skip: (this.page.current - 1) * this.page.size, // 略过数据
						Take: this.page.size, // 取的数据
					};
					data[params[0]] = this.searchKey;
					data[params[1]] = this.type;
					data[params[2]] = this.group ? [this.group] : null;
					data[params[3]] = this.brand;
					// data[params[4]] = this.checkedShopPro;
					// data[params[5]] = this.ActivityFullId
					// let result = await apiList[this.api](data);
					let result = await checkNogoodslist(data);

					this.page.total = result.Result.Total;
					this.tableDataList = result.Result.Results;
				} catch (error) {
					console.log(error);
				} finally {
					if (!this.isReady) {
						this.selectedData.map(item => {
							this.$refs['compSelectProTable'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
					this.loading = false;
				}
			},
			// 数组去重
			unique(arr) {
				const res = new Map();
				return arr.filter((arr) => !res.has(arr.ProductId) && res.set(arr.ProductId, 1))
			},
			//table原生全选按钮是否选中
			selectAll(selection) {
				// if(selection.length > this.goodsMaxlength){
				// 	this.$message({
				// 		message: '自定义推荐商品，最多可添加' + this.goodsMaxlength +'个',
				// 		type: 'error'
				// 	})
				// 	let newArray = []
				// 	newArray = this.selectedData.concat(selection)
				// 	this.justdata = this.unique(newArray)

				// 	console.log(this.justdata, '888888')
				// 	this.justdata.forEach(item => {
				// 		this.$refs['compSelectProTable'].toggleRowSelection(item, false);
				// 	})
				// 	this.selectedData.forEach(item => {
				// 		this.$refs['compSelectProTable'].toggleRowSelection(item, true);
				// 	})
				// 	this.$nextTick(function() {
				// 		this.$refs['compSelectProTable'].clearSelection() 
				// 	})
				// 	return 
				// }
				// this.$refs['compSelectProTable'].clearSelection() //用于多选表格，清空用户的选择
			},
			//外部全选当前页
			selectCurrentAll(e) {
				this.$refs['compSelectProTable'].toggleAllSelection()
			},
			//当前页全选
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 切换显示条数
			handleSizeChange(val) {
				console.log('切换条数')
				this.page.size = val;
				this.getSingleList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getSingleList()
			},
			selectInit(row) {
				// if(row.Id == 24){
				// 	 return false  //不可勾选
				// }
				// else{
				// 	return true
				// }
				return !row.IsDisabled
			},
			clearSelection() {
				this.$nextTick(() => {
					this.$refs.compSelectProTable.clearSelection();
				});
			},
			getRowKeys(row) {
				return row.ProductId;
			},
			handleFilter() {
				this.page.current = 1;
				this.getSingleList();
			},
			//查看可选商品
			showOptionalPro(e) {},
			//关闭弹框，以及曝光选择的列表
			saveSelectProList() {
				if(this.goodsMaxlength == 18){
					if (!this.multipleSelection.length) {
						this.$message({
							message: '请选择商品',
							type: 'info'
						})
						return
					}
				}
				
				if (this.goodsMaxlength>0&&this.multipleSelection.length > this.goodsMaxlength) {
					this.$message({
						message: '最多可选择' + this.goodsMaxlength + '个商品',
						type: 'error'
					})
					return
				}

				this.$emit('getSelectproList', this.multipleSelection)
			}

		}
	}
</script>
<style lang="less" scoped>
	.ellipsisCommon {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.contanier {
		position: relative;
		overflow: hidden;
	}

	.content {
		overflow: hidden;
	}

	.dialog-footer {
		margin-top: 20px;
		text-align: center;

		.button {
			width: 150px;
		}
	}

	.dialog-name-content {
		margin: 10px 0;
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}
	}
</style>
